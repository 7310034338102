import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/52de8f77b05d3f64963ab12421633895/aa440/Morne_The_Luminary_the_moon_celestial_body_that_orbits_our_worl_aed676d6-aad8-4173-929c-519717353cb8.png",
            "srcSet": ["/static/52de8f77b05d3f64963ab12421633895/5ff7e/Morne_The_Luminary_the_moon_celestial_body_that_orbits_our_worl_aed676d6-aad8-4173-929c-519717353cb8.png 375w", "/static/52de8f77b05d3f64963ab12421633895/1d69c/Morne_The_Luminary_the_moon_celestial_body_that_orbits_our_worl_aed676d6-aad8-4173-929c-519717353cb8.png 750w", "/static/52de8f77b05d3f64963ab12421633895/aa440/Morne_The_Luminary_the_moon_celestial_body_that_orbits_our_worl_aed676d6-aad8-4173-929c-519717353cb8.png 1500w", "/static/52de8f77b05d3f64963ab12421633895/71c1d/Morne_The_Luminary_the_moon_celestial_body_that_orbits_our_worl_aed676d6-aad8-4173-929c-519717353cb8.png 1536w"],
            "width": "600px",
            "className": "center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Rendition of the Luminary`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`The Luminary is the primary source of `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Magic on Naos`}</a>{`. The Luminary is worshipped by `}<a parentName="p" {...{
        "href": "/The%20Argent",
        "title": "The Argent"
      }}>{`The Argent`}</a>{` as a diety.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      